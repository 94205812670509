.sidebar-container {
  background-color: gray;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 4rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.sidebar-icon {
  background-color: #9ca3af;
  align-items: center;
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
  border-radius: 1.5rem;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: linear;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.sidebar-icon:hover {
  color: #f87171;
  background-color: rgb(255 255 255);
  border-radius: 0.75rem;
  cursor: pointer;
}
.sidebar-icon:last-child {
  margin-top: auto;
}

.toggle-theme-wrapper:hover {
  cursor: pointer;
}

.sidebar-tooltip {
  background-color: rgb(156 163 175);
  color: rgb(255, 255, 255);
  position: absolute;
  width: auto;
  padding: 0.5rem;
  margin: 0.5rem;
  min-width: max-content;
  left: 3.5rem;
  border-radius: 0.125rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  transform: scale(0);
  transform-origin: left;
  transition-property: all;
  transition-duration: 100ms;
  transition-timing-function: linear;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

.sidebar-icon:hover .sidebar-tooltip {
  transform: scale(1);
}
