.self-driving-container {
  height: 50vh;
  display: flex;
  justify-content: center;
}
/* 
#carCanvas {
  background: lightblue;
}

#networkCanvas {
  background: rgb(36, 36, 36);
} */
